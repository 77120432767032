import moment from "moment";
import { showPopup } from "./helpers";
import { apiAgent } from "@/services/apiAgent";
import {
  getNextAccountDate,
  replaceProductTags,
  formatCurrency,
  roundDecimals,
} from "@/mixins/utilFunctions";

export default {
  getInvoiceReceivers(contract) {
    return [contract.tenant, ...contract.otherTenants];
  },

  getPrincipalTenant(contract) {
    const tenants = this.getInvoiceReceivers(contract);
    const principalTenant = tenants.find((el) => !el.revokedDate);
    return principalTenant ? principalTenant : tenants[0];
  },

  getInvoiceTenantByClientId(contract, clientId) {
    const tenants = this.getInvoiceReceivers(contract);
    const tenant = tenants.find((el) => el.tenantId.clientId === clientId);
    return tenant ? tenant : tenants[0];
  },

  getInvoiceTenant(invoice, showUpdatedTenant = false) {
    const tenantClientId = showUpdatedTenant
      ? invoice.updatedTenant.clientId
      : invoice.tenant.clientId;
    const tenant = this.getInvoiceTenantByClientId(invoice.contract, tenantClientId);
    const tenantName = tenant.tenantId.name;
    const receiverName = showUpdatedTenant ? invoice.updatedTenant.name : invoice.tenant.name;
    return {
      tenant,
      isReceiver: String(tenantName).trim() === String(receiverName).trim(),
      showUpdatedTenant,
    };
  },

  getPrincipalTenantNameInfo(tenant) {
    return tenant.revokedDate ? `${tenant.tenantId.name} (irtisanottu)` : tenant.tenantId.name;
  },

  calcNextRecurrentBillDate(invoice) {
    const now = moment().format("YYYY-MM-DD");

    if (invoice.nextDueDate) {
      const nextDueDate = moment(moment(invoice.nextDueDate).format("YYYY-MM-DD"));
      let nextBillDate;

      if (invoice.activeRentIncreaseInUse && invoice.nextPaymentTerm) {
        nextBillDate = nextDueDate.subtract(invoice.nextPaymentTerm, "days").format("YYYY-MM-DD");
      } else {
        nextBillDate = nextDueDate.subtract(invoice.paymentTerm, "days").format("YYYY-MM-DD");
      }

      if (moment(now).isSameOrAfter(nextBillDate)) {
        return moment().add(1, "days").format("YYYY-MM-DD");
      } else return nextBillDate;
    } else return "";
  },

  getDatesFromContract(contract, account) {
    // Set auto dates only on new contract and contractract that has no baseinvoice already set
    let dueDate,
      billDate,
      accountDate,
      otherDueDatesDefault,
      setOtherDueDatesDefault = false,
      errorMessage;

    if (!contract.baseInvoice) {
      const firstRentDate = contract.lease.firstRentDate
        ? moment(contract.lease.firstRentDate).format("YYYY-MM-DD")
        : moment(contract.lease.startDate).format("YYYY-MM-DD");
      const otherDueDates = contract.lease.rentDueDateNumber
        ? contract.lease.rentDueDateNumber
        : account.settings.defaultDueDateNumber
        ? account.settings.defaultDueDateNumber
        : null;

      const year = new Date(firstRentDate).getFullYear();
      const month = new Date(firstRentDate).getMonth();

      // Other duedates exists
      if (otherDueDates) {
        if (otherDueDates === 100) {
          dueDate = moment(firstRentDate).endOf("month").format("YYYY-MM-DD");
          billDate = moment(dueDate).subtract(14, "days").format("YYYY-MM-DD");
          accountDate = billDate;
        } else {
          dueDate = moment(new Date(year, month, otherDueDates)).format("YYYY-MM-DD");
          billDate = moment(dueDate).subtract(14, "days").format("YYYY-MM-DD");
          accountDate = billDate;
        }
        // Set default number for otherDuedates
        setOtherDueDatesDefault = true;
        otherDueDatesDefault = otherDueDates;
      } else {
        errorMessage =
          "Sopimuksella tai tilin asetuksissa (vuokrasopimus) ei ole määritetty oletuseräpäivää. Et voi asettaa oletuseräpäiviä ennen kuin jompi kumpi näistä arvoista on määritetty.";
      }

      const today = moment().format("YYYY-MM-DD");

      // If duedate is in future (after today) but billdate is before today, set billdate as today
      if (moment(dueDate).isAfter(today) && moment(billDate).isBefore(today)) {
        billDate = today;
        accountDate = today;
        showPopup(
          "Laskun lähesty ei onnistu 14 päivää ennen eräpäivää (oletus). Laskun pvm. ja kirjauspäivä asetettu kuluvaan päivään.",
          "info"
        );
      } else if (moment(dueDate).isBefore(today)) {
        billDate = today;
        accountDate = today;
        dueDate = moment(today).add(14, "days").format("YYYY-MM-DD");
        showPopup(
          "Laskun eräpäivää ei voi asettaa menneisyyteen. Laskun pvm. ja kirjauspäivä asetettu kuluvaan päivään ja maksuehdoksi lisätty 14 päivää.",
          "info"
        );
      }
    }

    return {
      errorMessage,
      billDate,
      accountDate,
      dueDate,
      otherDueDatesDefault,
      setOtherDueDatesDefault,
    };
  },

  getDefaultProducts(contract) {
    const lease = contract.lease;
    const products = [];

    if (lease.rentProduct) {
      const prod = lease.rentProduct;

      products.push({
        desc: prod.desc,
        count: 1,
        itemtype: prod.itemtype,
        amount: lease.rent,
        taxpr: prod.taxpr,
        accountingId: prod.accountingId,
        productId: prod._id,
      });
    }

    if (lease.waterProduct) {
      const prod = lease.waterProduct;
      const count = lease.waterChargeCount || 1;

      products.push({
        desc: prod.desc,
        count,
        itemtype: prod.itemtype,
        amount: lease.waterChargePerMonth,
        taxpr: prod.taxpr,
        accountingId: prod.accountingId,
        productId: prod._id,
      });
    }

    if (lease.otherProducts && lease.otherProducts.length > 0) {
      lease.otherProducts.forEach((el) => {
        products.push({
          desc: el.productId.desc,
          count: el.count,
          itemtype: el.productId.itemtype,
          amount: el.amount,
          taxpr: el.productId.taxpr,
          accountingId: el.productId.accountingId,
          productId: el.productId._id,
        });
      });
    }

    return products;
  },

  calcTotalVats(items) {
    let vat10 = 0;
    let vat14 = 0;
    let vat24 = 0;
    let vat255 = 0;

    items.forEach((el) => {
      switch (el.taxpr) {
        case 10:
          if (canBeCalculated(el.count, el.amount)) vat10 += el.amount * el.count * 0.1;
          break;
        case 14:
          if (canBeCalculated(el.count, el.amount)) vat14 += el.amount * el.count * 0.14;
          break;
        case 24:
          if (canBeCalculated(el.count, el.amount)) vat24 += el.amount * el.count * 0.24;
          break;
        case 25.5:
          if (canBeCalculated(el.count, el.amount)) vat255 += el.amount * el.count * 0.255;
      }
    });

    function canBeCalculated(count, amount) {
      return (count < 0 || count > 0) && amount > 0;
    }

    return { vat10, vat14, vat24, vat255 };
  },

  hasOpenEquity(invoice) {
    const totalAmount = invoice.totalAmount;
    const openAmount = invoice.openAmount || 0;
    if (openAmount === 0) return false;

    const productsAmount = this.calcProductsTotalAmount(invoice);

    // Calc interests
    const interests = totalAmount - productsAmount;
    // If there are equity left, can make payment
    return roundDecimals(openAmount - interests) > 0;
  },

  async downloadInvoice(item, preview, statusCode, groupMode = false) {
    const invoiceService = item.service || null;
    if (!invoiceService) throw new Error("Laskua ei voi ladata. Service-kenttä puuttuu.");

    if (invoiceService === "ropoCapital")
      return await this.downloadRopoInvoice(item, preview, statusCode, groupMode);
    if (invoiceService === "talenom")
      return await this.downloadInvoiceFromService(item, item.talenom.invoiceId, groupMode);
  },

  async downloadRopoInvoice(item, preview, statusCode, groupMode = false) {
    let url;

    if (groupMode) {
      url = preview
        ? `/api/v1/ropo24/preview-invoice/${item._id}?accountId=${item.createdBy._id}`
        : `/api/v1/ropo24/downloadInvoice/${item.ropoCapital.jobId}?accountId=${item.createdBy._id}&statusCode=${statusCode}`;
    } else {
      url = preview
        ? `/api/v1/ropo24/preview-invoice/${item._id}`
        : `/api/v1/ropo24/downloadInvoice/${item.ropoCapital.jobId}?statusCode=${statusCode}`;
    }

    const res = await apiAgent({
      method: "GET",
      url,
      responseType: "arraybuffer",
    });

    return new Blob([res.data], { type: "application/pdf" });
  },

  async downloadInvoiceFromService(item, invoiceId, groupMode = false) {
    let url;

    if (groupMode) {
      url = `/api/v1/invoices/${invoiceId}/download?accountId=${item.createdBy._id}&groupMode=${groupMode}&service=${item.service}`;
    } else {
      url = `/api/v1/invoices/${invoiceId}/download?accountId=${item.createdBy}&groupMode=${groupMode}&service=${item.service}`;
    }

    const res = await apiAgent({
      method: "GET",
      url,
      responseType: "blob",
    });

    return res.data;
  },

  // Calculate invoice products amount
  calcProductsTotalAmount(invoice) {
    return invoice.products.reduce((sum, cur) => {
      sum += cur.count * cur.amount * (1 + cur.taxpr / 100);
      return sum;
    }, 0);
  },

  formatRecurrentInvoiceProducts(invoice) {
    let fixedPeriodActive = false;
    let productsString = "";
    let totalAmount = 0;

    if (invoice.fixedPeriodRecurrency?.inUse) {
      const nextDueDate = new Date(moment(invoice.nextDueDate).format("YYYY-MM-DD"));
      const startDate = new Date(
        moment(invoice.fixedPeriodRecurrency.startDate).format("YYYY-MM-DD")
      );

      fixedPeriodActive =
        moment(nextDueDate).isSameOrAfter(moment(startDate, "month")) &&
        invoice.fixedPeriodRecurrency.repeatsLeft > 0
          ? true
          : false;
    }

    const PRODUCT_VAL = fixedPeriodActive
      ? "fixedPeriodProducts"
      : invoice.isUpdated
      ? "updatedProducts"
      : "products";

    invoice[PRODUCT_VAL].forEach((el) => {
      const amount = el.count * (el.amount * (1 + el.taxpr / 100));
      totalAmount += amount;

      if (invoice.nextDueDate) {
        productsString += `<div>${replaceProductTags(
          el.desc,
          invoice.nextDueDate,
          getNextAccountDate(invoice),
          invoice.language
        )} | ${formatCurrency(amount)}</div>`;
      } else {
        productsString += `<div>${replaceProductTags(
          el.desc,
          invoice.dueDate,
          getNextAccountDate(invoice),
          invoice.language
        )} | ${formatCurrency(amount)}</div>`;
      }
    });

    return { productsString, totalAmount };
  },

  invoiceIsNotDeptCollected(invoice) {
    if (!invoice) return false;
    if (invoice.comments) {
      const isPaid = invoice.openAmount == 0;
      // If paid, return false, if its not paid, check if its in depdt collection (status 2).
      return isPaid
        ? false
        : !invoice.comments.some(
            (obj) =>
              obj.statusCode == 2 ||
              obj.title.toLowerCase().includes("perintä") ||
              obj.text.toLowerCase().includes("perintä")
          );
    } else return false;
  },
};
